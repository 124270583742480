import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useInterval } from 'react-use';
import { Heading, Stack, Text } from '@carvertical/ui';
import { Page } from 'components/common/Page';
import { Section } from './common/Section';
import styles from './NotFoundPage.module.scss';

const REDIRECT_AFTER_SECONDS = 3;
const COUNTDOWN_INTERVAL = 1000;

const NotFoundPage = () => {
  const { t } = useTranslation('common');

  const router = useRouter();

  const [redirectCountdown, setRedirectCountdown] = useState(REDIRECT_AFTER_SECONDS);

  const redirectDelay = redirectCountdown > 0 ? COUNTDOWN_INTERVAL : null;

  useInterval(() => {
    setRedirectCountdown((countdown) => Math.max(countdown - 1, 0));
  }, redirectDelay);

  if (redirectCountdown <= 1) {
    router.push({ pathname: '/' });
  }

  return (
    <Page>
      <Section className={styles.section}>
        <Stack gap={2} crossAxisAlign="center">
          <Heading variant="l" as="h1" className={styles.title}>
            {t('notFoundPage.pageTitle')}
          </Heading>

          <Text>{t('notFoundPage.text')}</Text>

          <Text>
            {t('notFoundPage.redirectingCountdown', {
              timeLeft: redirectCountdown,
            })}
          </Text>
        </Stack>
      </Section>
    </Page>
  );
};

export { NotFoundPage };
